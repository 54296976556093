import { Box, Grid, IconButton, Stack, Typography, Card, Badge, CircularProgress } from '@mui/material';
import MDButton from 'components/MDButton';
import CommonLayout from 'layouts/DashboardLayout';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openSnackbar } from '../../redux/slices/common';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice';
import { deleteWebCategory, fetchWebCategories, updateCategoriesOrder } from '../../redux/slices/category';
import EditIcon from 'common/customIcons/edit';
import DeleteIcon from 'common/customIcons/delete';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import MenuIcon from '@mui/icons-material/Menu';


const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  background: isDragging ? '' : '',
  border: isDragging ? 'none' : '',
  display: 'flex',
  width: '100%',

  // styles we need to apply on draggables
  ...draggableStyle
});

export default function ManageCategories() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFetching = false, webCategoriesList = {}, loading = false } = useSelector(state => state.category);
  const pageRef = useRef(0);
  const searchTermRef = useRef('');
  const mainGridApiRef = useRef('');
  const [categoriesList, setCategoriesList] = useState([]);

  useEffect(() => {
    if (webCategoriesList?.values?.length) {
      setCategoriesList(webCategoriesList?.values);

    }
  }, [webCategoriesList?.values]);

  const deleteHandler = (webCategoryId) => {
    dispatch(setModalLoader(true));
    dispatch(deleteWebCategory(webCategoryId)).unwrap().then((res) => {
      showError(res?.message, 'success');
      dispatch(closeCustomModalDialog());
      dispatch(fetchWebCategories({ skip: pageRef.current, limit: 100, userType: 'admin', searchTerm: searchTermRef.current }));
    }).catch((err) => {
      dispatch(setModalLoader(false));
      showError(err?.message, 'error');
    });
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      categoriesList,
      result.source.index,
      result.destination.index
    );
    let newOrderedCat = [...items].map((v, ind) => {
      return { ...v, order: ind + 1 };
    });
    setCategoriesList(newOrderedCat);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  useEffect(() => {
    dispatch(fetchWebCategories({ skip: pageRef.current, limit: 100, userType: 'admin', searchTerm: searchTermRef.current }));
  }, []);

  const saveCategoriesOrder = () => {
    dispatch(updateCategoriesOrder(categoriesList)).unwrap().then((res) => {
      showError(res?.message, 'success');
      dispatch(closeCustomModalDialog());
    }).catch((err) => {
      showError(err?.message, 'error');
    });
  };

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (isFetching) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (webCategoriesList?.total === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      }
      else {
        mainGridApiRef.current.hideOverlay();
      }
    }

  }, [webCategoriesList, isFetching]);
  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }}>

        <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant='h4'>Manage Categories</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Stack direction="row" justifyContent={{ xs: 'start', sm: 'end' }} flexWrap="wrap" alignItems="stretch" gap={2}>
              {/* <Search placeholder="Search" label="" value={searchTerm} onChange={searchHandler} /> */}
              <MDButton
                startIcon={<AddRoundedIcon fontSize='large' />}
                variant="contained" color="primary"
                onClick={() => navigate('/manageCategories/add')} >
                Add Web Category
              </MDButton>
            </Stack>
          </Grid>
        </Grid>

        <Card sx={{ p: { xs: 2, md: 3 }, borderRadius: 2.5, boxShadow: 'none' }}>
          {
            isFetching ? <Box textAlign='center'>
              <CircularProgress size={30} />
            </Box> : <Grid container alignItems="center" rowSpacing={2} columnSpacing={2} mb={3}>
              <Grid item xs={12} lg={12} className="manage-cat-sec">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <ul
                        className="category-ul-list"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {categoriesList?.length > 0 &&
                          categoriesList?.map((item, index) => {
                            return (
                              <Draggable
                                key={item._id}
                                draggableId={item._id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  // <li
                                  //   className="custon-list"
                                  //   ref={provided.innerRef}
                                  //   {...provided.draggableProps}
                                  //   {...provided.dragHandleProps}
                                  //   style={getItemStyle(
                                  //     snapshot.isDragging,
                                  //     provided.draggableProps.style
                                  //   )}

                                  // >
                                  <li
                                    className="custon-list"
                                    ref={provided.innerRef}  // Explicitly pass the ref
                                    draggable={provided.draggableProps.draggable} // Pass draggableProps explicitly
                                    onDragStart={provided.dragHandleProps.onDragStart} // Handle drag events explicitly
                                    onDragEnd={provided.dragHandleProps.onDragEnd} // Handle drag events explicitly
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style // Provide style directly
                                    )}
                                  >
                                    <MenuIcon className="mr-2" />
                                    <div className="category-img-sec mr-2">
                                      <img
                                        src={item?.image}
                                        className="category-img"
                                        alt=""
                                      />
                                    </div>
                                    <div className="left-section mr-2">
                                      <label>Category</label>
                                      <Badge>{item.webCategoryName}</Badge>
                                    </div>
                                    <div className="sub-category-name-sec mr-2">
                                      <label>Sub Categories</label>
                                      {
                                        item.adminCategories.map((category => {
                                          return <Badge className="mr-2" key={category?.categoryId}>{category?.categoryName}</Badge>;
                                        }))
                                      }

                                    </div>

                                    <div>
                                      <IconButton onClick={() => navigate(`/manageCategories/${item._id}`)} color='primary' size='small' variant="primary">
                                        <EditIcon width='16px' height='16px' />
                                      </IconButton>
                                      <IconButton onClick={() => dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: item?._id }, modal: 'DELETE_MODAL' }))} color='error' size='small' variant="primary" sx={{ marginLeft: 1 }}>
                                        <DeleteIcon width='16px' height='16px' />
                                      </IconButton>
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.25} mt={4} mb={2}>
                  <MDButton
                    sx={{ minWidth: '140px' }}
                    type='button'
                    variant='contained'
                    color='primary'
                    disabled={loading}
                    loading={loading}
                    onClick={saveCategoriesOrder}>
                    Save
                  </MDButton>
                </Stack>

              </Grid>
            </Grid>
          }

        </Card>

      </Box>
    </CommonLayout>
  );
}