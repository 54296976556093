
import Dashboard from 'layouts/dashboard';
// @mui icons
import { QuestionAnswer, WallpaperRounded, WorkspacePremium } from '@mui/icons-material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Products from 'layouts/products';
import Inventory from 'layouts/inventory';
import Members from 'layouts/members';
import Brands from 'layouts/brands';
import Regions from 'layouts/regions';
import Orders from 'layouts/orders';
import AddMember from 'layouts/members/addMember';
import MemberDetails from 'layouts/members/memberDetails';
import AddProduct from 'layouts/products/addProduct';
import Categories from 'layouts/categories';
import AddRegion from 'layouts/regions/addRegion';
import AddCategory from 'layouts/categories/addCategory';
import AddInventory from 'layouts/inventory/addInventory';
import AddOrder from 'layouts/orders/addOrder';
import AddBrand from 'layouts/brands/addBrand';
import ProductIcon from 'common/customIcons/product';
import InventoryIcon from 'common/customIcons/inventory';
import OrdersIcon from 'common/customIcons/orders';
import DashboardIcon from 'common/customIcons/dashboard';
import MembersIcon from 'common/customIcons/members';
import BrandsIcon from 'common/customIcons/brands';
import CategoriesIcon from 'common/customIcons/categories';
import SettingsIcon from 'common/customIcons/settings';
import AddWebCategory from 'layouts/manageCategories/addWebCategory';
import ProductDetails from 'layouts/products/productDetails';
import OrderDetailsPage from 'layouts/orders/orderDetails';
import TerpenesIcon from 'common/customIcons/terpenes';
import RegionsIcon from 'common/customIcons/regions';
import Strains from 'layouts/strains';
import AddStrain from 'layouts/strains/addStrain';
import AddMainStrain from 'layouts/strains/addMainStrain';
import StrainsIcon from 'common/customIcons/strains';
import MemberOrders from 'layouts/orders/memberOrders';
import AddMainBrand from 'layouts/brands/addMainBrand';
import ManageSignupType from 'layouts/manageSingupType';
import ManageRegisterationIcon from 'common/customIcons/manage_registeration';
import MainFAQ from 'layouts/mainFAQ';
import HomepageBanners from 'layouts/mainBanners';
import AddBanner from 'layouts/mainBanners/AddBanner';
import Promotions from 'layouts/promotions';
import AddPromotion from 'layouts/promotions/addPromotion';
import PromotionDetails from 'layouts/promotions/promotionDetails';
import PromotionsIcon from 'common/customIcons/promotions';
import ManageDisclaimers from 'layouts/disclaimers';
import MemberShips from 'layouts/memberships';
import AddMemberShip from 'layouts/memberships/addMemberShip';
import AddRole from 'layouts/rolesAndPermissions/addRole';
import UsersAndRoles from 'layouts/rolesAndPermissions';
import Retailers from 'layouts/retailers';
import AddRetailer from 'layouts/retailers/addRetailer';
import RetailerDetails from 'layouts/retailers/retailerDetails';
import RetailerProductDetailsPage from 'components/retailers/Products/productDetails';
import MasterCatalogProductList from 'layouts/masterCatalog/MasterCatalog';
import MasterCatalog from 'layouts/masterCatalog';
import AddRetailerProducts from 'components/retailers/addRetailerProducts';
import Approvals from 'layouts/approvals';
import RetailerApprovalDetails from 'components/retailers/Approvals/approvalDetails';
import RetailersIcon from 'common/customIcons/retailters';
import ApprovalsIcon from 'common/customIcons/approvals';
import MastercatalogIcon from 'common/customIcons/master-catlog';
import HyperDrive from 'layouts/Hyperdrive';
import HyperwolfAdmin from 'layouts/hyperwolf';
import DriverIcon from 'common/customIcons/driver';
import ManageHyperwolfCategories from 'layouts/hyperwolf/manageCategories';
import ManageEmployees from 'layouts/hyperwolf/manageEmployee';
import AddEmployee from 'layouts/hyperwolf/manageEmployee/AddEmployee';
import HyperwolfShopTiming from 'layouts/hyperwolf/manageShopTimings';
import ReorderIcon from '@mui/icons-material/Reorder';
import HyperwolfMainStrains from 'layouts/hyperwolf/manageMainStrains';
import AddHyperwolfStrain from 'layouts/hyperwolf/manageStrains/AddStrain';
import HyperwolfMainBanners from 'layouts/hyperwolf/manageHyperwolfBanners';
import AddHyperwolfBanner from 'layouts/hyperwolf/manageHyperwolfBanners/AddBanner';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import HyperwolfProducts from 'layouts/hyperwolf/products';
import AddHyperwolfCategory from 'layouts/hyperwolf/manageCategories/addWebCategory';

import CreateBreak from 'layouts/hyperwolf/Break/createBreak';
import Approval from 'layouts/hyperwolf/Approval';
import DriverDetails from 'layouts/hyperwolf/Driver/driverDetails';
import AddDriver from 'layouts/hyperwolf/Driver/AddDriver/addDriver';
import AddBreak from 'layouts/hyperwolf/Break/AddBreak/addBreak';
import AddCannabinoid from 'layouts/cannabinoids/addCannabinoid';
import ManageCannabinoids from 'layouts/cannabinoids/manageCannabinoids';
import AddMainCannabinoid from 'layouts/cannabinoids/manageCannabinoids/AddMainCannabinoid';
import AddCannabinoidBanner from 'layouts/cannabinoids/manageCannabinoids/ManageMainCannabinoidBanners/AddCannabinoidBanner';
import MainPageCannbinoid from 'layouts/cannabinoids/manageCannabinoids/MainPageCannabinoid';
// import HyperDriveHomePage from 'layouts/Hyperdrive';
// import SiteSetting from 'layouts/Hyperdrive/SiteSetting';
// import DriverAnalyticsDetails from 'layouts/Hyperdrive/DriverAnalytics/DriverDetails';
import React, { Suspense, lazy } from 'react';
const HyperDriveHomePage = lazy(() => import('layouts/Hyperdrive'));
const SiteSetting = lazy(() => import('layouts/Hyperdrive/SiteSetting'));
const DriverAnalyticsDetails = lazy(() => import('layouts/Hyperdrive/DriverAnalytics/DriverDetails'));

import HyperwolfOrders from 'layouts/hyperwolf/orders';
import HyperwolfOrderDetailsPage from 'layouts/hyperwolf/orders/orderDetails';
import HyperwolfMembers from 'layouts/hyperwolf/members';
import HyperwolfMemberDetails from 'layouts/hyperwolf/members/memberDetails';
import DriverHemp from 'layouts/Driver';
import ApprovalHemp from 'layouts/Approval';
import AddDriverHemp from 'layouts/Driver/AddDriver/addDriver';
import CreateBreakHemp from 'layouts/Break/createBreak';
import DriverDetailsHemp from 'layouts/Driver/driverDetails';
import AddBreakHemp from 'layouts/Break/AddBreak/addBreak';
import HyperdriveLoading from 'common/HyperdriveLoading';

const routes = [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <DashboardIcon />,
    route: '/dashboard',
    component: <Dashboard />,
    roles: ['Super Admin', 'Developer']
  },
  {
    name: 'Add Retailer',
    key: 'retailers/add',
    icon: <DashboardIcon />,
    route: 'retailers/add',
    component: <AddRetailer />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support'],
    screen: 'products'
  },
  {
    name: 'Retailer Details',
    key: 'retailers/details',
    icon: <MembersIcon />,
    route: '/retailers/detail/:retailerId',
    component: <RetailerDetails />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']

  },
  {
    name: 'Retailer Details',
    key: 'retailers/update',
    icon: <MembersIcon />,
    route: '/retailers/:retailerId',
    component: <AddRetailer />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']

  },
  {
    name: 'Update Prroduct',
    key: 'retailerProducts/update',
    icon: <ProductIcon />,
    route: '/retailers/detail/:retailerId/products/:productId',
    component: <RetailerProductDetailsPage />,
    roles: ['Super Admin', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer']
  },
  {
    type: 'collapse',
    name: 'POS',
    icon: <RetailersIcon />,
    roles: ['Super Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
    screen: 'products',
    collapse: [
      {
        type: 'collapse',
        name: 'Retailers',
        key: 'retailers',
        icon: <RetailersIcon />,
        route: '/retailers',
        component: <Retailers />,
        roles: ['Super Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
        screen: 'products'
      },
      {
        type: 'collapse',
        name: 'Approvals',
        key: 'approvals',
        icon: <ApprovalsIcon />,
        route: '/approvals',
        component: <Approvals />,
        roles: ['Super Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
        screen: 'products'
      },
    ]
  },
  {
    type: 'collapse',
    name: 'Products',
    icon: <ProductIcon />,
    roles: ['Super Admin', 'Sales Associate', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
    screen: 'products',
    collapse: [
      {
        type: 'collapse',
        name: 'Products',
        key: 'products',
        icon: <ProductIcon />,
        route: '/products',
        component: <Products />,
        roles: ['Super Admin', 'Sales Associate', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
        screen: 'products',
      },
      {
        name: 'Master Catalog',
        key: 'mastercatalog',
        icon: <MastercatalogIcon />,
        route: 'mastercatalog',
        component: <MasterCatalog mode='view' />,
        roles: ['Super Admin', 'Sales Associate', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
        screen: 'products',
      },
      {
        type: 'collapse',
        name: 'Disclaimer',
        key: 'disclaimer',
        icon: <SettingsIcon />,
        route: '/disclaimer',
        component: <ManageDisclaimers />,
        roles: ['Super Admin', 'Sales Associate', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
        screen: 'products',
      },
    ]
  },
  {
    name: 'Add RetailerProducts',
    key: 'retailerProducts/add',
    route: '/retailer/product/:retailerId/add',
    component: <AddRetailerProducts />,
    roles: ['Super Admin', 'Sales Associate', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
  },
  {
    name: 'Add RetailerProducts',
    key: 'retailerProducts/add',
    route: '/retailer/product/:retailerId/bulkadd',
    component: <MasterCatalogProductList />,
    roles: ['Super Admin', 'Sales Associate', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
  },
  {
    name: 'Retailer Approvals',
    key: 'retailerapprovals/approvalDetails',
    route: '/retailers/detail/:retailerId/:approvalId',
    component: <RetailerApprovalDetails />,
    roles: ['Super Admin', 'Sales Associate', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
  },
  {
    name: 'Add Product',
    key: 'products/add',
    icon: <ProductIcon />,
    route: '/products/add',
    component: <AddProduct />,
    roles: ['Super Admin', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer']
  },
  {
    name: 'Update Prroduct',
    key: 'products/update',
    icon: <ProductIcon />,
    route: '/products/:productId',
    component: <AddProduct />,
    roles: ['Super Admin', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer']
  },
  {
    name: 'Update Prroduct',
    key: 'products/update',
    icon: <ProductIcon />,
    route: '/products/details/:productId',
    component: <ProductDetails />,
    roles: ['Super Admin', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer']
  },
  {
    type: 'collapse',
    name: 'Inventory',
    key: 'inventories',
    icon: <InventoryIcon />,
    route: '/inventories',
    component: <Inventory />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Inventory Manager']
  },
  {
    name: 'Add Inventory',
    key: 'inventories/add',
    icon: <InventoryIcon />,
    route: '/inventories/add',
    component: <AddInventory />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Inventory Manager']
  },
  {
    name: 'Update Inventory',
    key: 'inventories/update',
    icon: <InventoryIcon />,
    route: '/inventories/:inventoryId',
    component: <AddInventory />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Inventory Manager']
  },
  {
    type: 'collapse',
    name: 'Orders',
    key: 'orders',
    icon: <OrdersIcon />,
    route: '/orders',
    component: <Orders />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Orders',
    key: 'orders',
    icon: <OrdersIcon />,
    route: '/orders/member/:memberId',
    component: <MemberOrders />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']

  },
  {
    name: 'Orders',
    key: 'orders/add',
    icon: <OrdersIcon />,
    route: '/orders/add',
    component: <AddOrder />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']

  },
  {
    name: 'Orders',
    key: 'orders/update',
    icon: <OrdersIcon />,
    route: '/orders/:orderId',
    component: <AddOrder />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']

  },
  {
    name: 'Orders',
    key: 'orders/update',
    icon: <OrdersIcon />,
    route: '/orders/details/:orderId',
    component: <OrderDetailsPage />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'RetailerOrders',
    key: 'retailerOrders/update',
    icon: <OrdersIcon />,
    route: '/retailers/detail/:retailerId/orders/:orderId',
    component: <OrderDetailsPage />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    type: 'collapse',
    name: 'Members',
    key: 'members',
    icon: <MembersIcon />,
    route: '/members',
    component: <Members />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']

  },
  {
    name: 'Members Details',
    key: 'members/details',
    icon: <MembersIcon />,
    route: '/members/detail/:memberId',
    component: <MemberDetails />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']

  },
  {
    name: 'Add Member',
    key: 'members/add',
    icon: <MembersIcon />,
    route: '/members/add',
    component: <AddMember />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']

  },
  {
    name: 'Member Details',
    key: 'members/update',
    icon: <MembersIcon />,
    route: '/members/:memberId',
    component: <AddMember />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']

  },
  {
    name: 'RetailerMembers',
    key: 'retailerMembers/update',
    icon: <MembersIcon />,
    route: '/retailers/detail/:retailerId/members/:memberId',
    component: <MemberDetails />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },

  //Hemp drivers 
  // {
  //   type: 'collapse',
  //   name: 'Driver/Breaks',
  //   key: 'hemp/driver',
  //   icon: <DriverIcon />,
  //   route: '/drivers',
  //   component: <DriverHemp />,
  //   roles: ['Super Admin', 'Developer']
  // },
  {
    name: 'Driver',
    key: 'hemp/driver',
    icon: <DriverIcon />,
    route: '/driver/create',
    component: <AddDriverHemp />,
    roles: ['Super Admin', 'Developer']
  },
  {
    name: 'Driver Hemp',
    key: 'retailers/update',
    icon: <MembersIcon />,
    route: '/driver/:driverId',
    component: <AddDriverHemp />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Driver Details Hemp',
    key: '/driver/details',
    icon: <MembersIcon />,
    route: '/driver/details/:driverId',
    component: <DriverDetailsHemp />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Break',
    key: 'driver/break',
    icon: <DriverIcon />,
    route: '/break/create',
    component: <CreateBreakHemp />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Break Details',
    key: '/driver/break/update',
    icon: <MembersIcon />,
    route: '/break/:breakId',
    component: <AddBreakHemp />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Approval',
    key: 'hyperwolf/driver/approval',
    icon: <DriverIcon />,
    route: '',
    component: <ApprovalHemp />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    type: 'collapse',
    name: 'Manage Website',
    icon: <CategoriesIcon />,
    roles: ['Super Admin', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
    screen: 'products',
    collapse: [
      {
        type: 'collapse',
        name: 'Categories',
        key: 'categories',
        icon: <CategoriesIcon />,
        route: '/categories',
        component: <Categories />,
        roles: ['Super Admin', 'Shop Admin', 'Developer', 'Inventory Manager', 'Marketing Manager'],
        screen: 'products',
      },
      {
        type: 'collapse',
        name: 'Strains',
        key: 'strains',
        icon: <StrainsIcon />,
        route: '/strains',
        component: <Strains />,
        roles: ['Super Admin', 'Shop Admin', 'Developer', 'Inventory Manager', 'Marketing Manager'],
        screen: 'products',
      },
      {
        type: 'collapse',
        name: 'Cannabinoids',
        key: 'manage-cannabinoids',
        icon: <TerpenesIcon />,
        route: '/manage-cannabinoids',
        component: <ManageCannabinoids />,
        roles: ['Super Admin', 'Shop Admin', 'Developer', 'Inventory Manager', 'Marketing Manager'],
        screen: 'products',
      },
      {
        type: 'collapse',
        name: 'Brands',
        key: 'brands',
        icon: <BrandsIcon />,
        route: '/brands',
        component: <Brands />,
        roles: ['Super Admin', 'Shop Admin', 'Developer', 'Inventory Manager', 'Marketing Manager'],
        screen: 'products',
      },
      {
        type: 'collapse',
        name: 'Homepage FAQ',
        key: 'faqs',
        icon: <QuestionAnswer />,
        route: '/faqs',
        component: <MainFAQ />,
        roles: ['Super Admin', 'Shop Admin', 'Developer', 'Inventory Manager', 'Marketing Manager'],
        screen: 'products',
      },
      {
        type: 'collapse',
        name: 'Homepage Banners',
        key: 'homepage-banners',
        icon: <WallpaperRounded />,
        route: '/homepage-banners',
        component: <HomepageBanners />,
        roles: ['Super Admin', 'Shop Admin', 'Developer', 'Inventory Manager', 'Marketing Manager'],
        screen: 'products',
      },
    ]
  },
  {
    type: 'collapse',
    name: 'Promotions',
    // key: 'products',
    icon: <PromotionsIcon />,
    roles: ['Super Admin', 'Shop Admin', 'Shop Manager',  'Developer'],
    screen: 'products',
    collapse: [
      {
        type: 'collapse',
        name: 'Promotions',
        key: 'promotions',
        icon: <PromotionsIcon />,
        route: '/promotions',
        component: <Promotions />,
        roles: ['Super Admin', 'Shop Admin', 'Shop Manager',  'Developer'],
        screen: 'products',
      },
      {
        type: 'collapse',
        name: 'Membership',
        key: 'memberships',
        icon: <WorkspacePremium />,
        route: '/memberships',
        component: <MemberShips />,
        roles: ['Super Admin', 'Shop Admin', 'Shop Manager',  'Developer'],
        screen: 'products',
      },
    ]
  },


  {
    type: 'collapse',
    name: 'Settings',
    key: 'settings',
    icon: <SettingsIcon />,
    roles: ['Super Admin', 'Shop Admin', 'Developer'],
    screen: 'settings',
    collapse: [
      {
        type: 'collapse',
        name: 'Users and Roles',
        key: 'user-roles',
        icon: <ManageAccountsIcon />,
        route: '/user-roles',
        component: <UsersAndRoles />,
        roles: ['Super Admin', 'Shop Admin', 'Developer'],
        screen: 'settings',
      },
      {
        type: 'collapse',
        name: 'Regions',
        key: 'regions',
        icon: <RegionsIcon />,
        route: '/regions',
        component: <Regions />,
        roles: ['Super Admin', 'Shop Admin', 'Developer'],
        screen: 'settings',
      },
      {
        type: 'collapse',
        name: 'Manage Platforms',
        key: 'manages/registration',
        icon: <ManageRegisterationIcon />,
        route: '/manages/registration',
        component: <ManageSignupType />,
        roles: ['Super Admin', 'Shop Admin', 'Developer'],
        screen: 'settings',
      },
    ]
  },

  {
    name: 'add Category',
    key: 'categories/add',
    icon: <CategoriesIcon />,
    route: '/categories/add',
    component: <AddCategory />,
    roles: ['Super Admin', 'Developer', 'Inventory Manager', 'Marketing Manager']
  },
  {
    name: 'update Category',
    key: 'categories/update',
    icon: <CategoriesIcon />,
    route: '/categories/:categoryId',
    component: <AddCategory />,
    roles: ['Super Admin', 'Developer', 'Inventory Manager', 'Marketing Manager']
  },

  {
    name: 'add strains',
    key: '/strains ',
    icon: <StrainsIcon />,
    route: '/strains/add',
    component: <AddStrain />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager']

  },
  {
    name: 'add strains',
    key: '/strains ',
    icon: <StrainsIcon />,
    route: '/strains/:strainId',
    component: <AddStrain />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager']

  },
  {
    name: 'add strains',
    key: '/strains ',
    icon: <StrainsIcon />,
    route: '/strains/main-strain',
    component: <AddMainStrain />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager']
  },

  {
    name: 'Manage Categories',
    key: '/manageCategories ',
    icon: <CategoriesIcon />,
    route: '/manageCategories/add',
    component: <AddWebCategory />,
    roles: ['Super Admin', 'Developer', 'Inventory Manager', 'Marketing Manager']
  },
  {
    name: 'Manage Categories',
    key: '/manageCategories ',
    icon: <CategoriesIcon />,
    route: '/manageCategories/:webCategoryId',
    component: <AddWebCategory />,
    roles: ['Super Admin', 'Developer', 'Inventory Manager', 'Marketing Manager']
  },

  {
    name: 'Brands',
    key: 'brands/add',
    icon: <BrandsIcon />,
    route: '/brands/add',
    component: <AddBrand />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager']
  },
  {
    name: 'Main Brand',
    key: 'brands/main-brand',
    icon: <BrandsIcon />,
    route: '/brands/main-brand',
    component: <AddMainBrand />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager']
  },
  {
    name: 'Brands Update',
    key: 'brands/update',
    icon: <BrandsIcon />,
    route: '/brands/:brandId',
    component: <AddBrand />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager']
  },
  {
    name: 'add Region',
    key: 'regions/add',
    icon: <RegionsIcon />,
    route: '/regions/add',
    component: <AddRegion />,
    roles: ['Super Admin', 'Developer', 'Shop Manager']
  },
  {
    name: 'edit Region',
    key: 'regions/update',
    icon: <RegionsIcon />,
    route: '/regions/:regionId',
    component: <AddRegion />,
    roles: ['Super Admin', 'Developer', 'Shop Manager']

  },
  {
    name: 'Main Cannabinoids',
    key: 'manage-cannabinoids/main-cannabinoid',
    icon: <TerpenesIcon />,
    route: '/manage-cannabinoids/main-cannabinoid',
    component: <AddMainCannabinoid />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager']
  },
  {
    name: 'Canabanoids',
    key: 'cannabinoids',
    icon: <TerpenesIcon />,
    route: '/cannabinoids/add',
    component: <AddCannabinoid />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager'],
  },
  {
    name: 'Canabanoids',
    key: 'cannabinoids',
    icon: <TerpenesIcon />,
    route: '/cannabinoids/:cannabinoidId',
    component: <AddCannabinoid />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager']
  },
  {
    name: 'Main Cannabinoids',
    key: 'manage-cannabinoids/main-cannabinoid',
    icon: <TerpenesIcon />,
    route: '/manage-cannabinoids/main-cannabinoid',
    component: <AddMainCannabinoid />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager']
  },
  {
    name: 'Main Cannabinoids',
    key: 'manage-cannabinoids/add-main-cannabinoid-banner',
    icon: <TerpenesIcon />,
    route: '/manage-cannabinoids/add-main-cannabinoid-banner',
    component: <AddCannabinoidBanner />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager']
  },
  {
    name: 'Main Cannabinoids',
    key: 'manage-cannabinoids/add-main-cannabinoid-banner',
    icon: <TerpenesIcon />,
    route: '/manage-cannabinoids/add-main-cannabinoid-banner/:bannerId',
    component: <AddCannabinoidBanner />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager']
  },
  {
    name: 'Main Cannabinoids',
    key: 'manage-cannabinoids/main-cannabinoid/main-page',
    icon: <TerpenesIcon />,
    route: '/manage-cannabinoids/main-cannabinoid/main-page',
    component: <MainPageCannbinoid />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager']
  },
  {
    name: 'Add Homepage Banners',
    key: 'add-homepage-banners',
    icon: <WallpaperRounded />,
    route: '/homepage-banners/add',
    component: <AddBanner />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager', 'Shop Admin']
  },
  {
    name: 'Add Homepage Banners',
    key: 'update-homepage-banners',
    icon: <WallpaperRounded />,
    route: '/homepage-banners/:bannerId',
    component: <AddBanner />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager', 'Shop Admin']
  },
  {
    name: 'Add Promotion',
    key: 'promotions',
    icon: <PromotionsIcon />,
    route: '/promotions/add',
    component: <AddPromotion />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager', 'Shop Admin']
  },
  {
    name: 'Add Promotion',
    key: 'promotions',
    icon: <PromotionsIcon />,
    route: '/promotions/:promotionId',
    component: <AddPromotion />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager', 'Shop Admin']
  },
  {
    name: 'Add Promotion',
    key: 'promotions',
    icon: <PromotionsIcon />,
    route: '/promotions/:promotionId/details',
    component: <PromotionDetails />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager', 'Shop Admin']
  },
  {
    name: 'Membership',
    key: 'membership',
    icon: <WorkspacePremium />,
    route: '/memberships/add',
    component: <AddMemberShip />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager', 'Shop Admin']
  },
  {
    name: 'Membership',
    key: 'membership',
    icon: <WorkspacePremium />,
    route: '/memberships/:memberShipId/update',
    component: <AddMemberShip />,
    roles: ['Super Admin', 'Developer', 'Marketing Manager', 'Shop Admin']
  },

  {
    name: 'Roles',
    key: 'roles',
    route: '/user-roles/add',
    component: <AddRole />,
    roles: ['Super Admin', 'Developer']

  },
  {
    name: 'Roles',
    key: 'roles',
    route: '/user-roles/:roleId',
    component: <AddRole />,
    roles: ['Super Admin', 'Developer']

  },
];

export const hyperfwolfAdminRoutes = [
  {
    type: 'collapse',
    name: 'Manage Website',
    // key: 'products',
    icon: <CategoriesIcon />,
    // route: '/products',
    // component: <Products />,
    roles: ['Super Admin', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
    screen: 'products',
    collapse: [
      {
        // type: 'collapse',
        name: 'Cannabinoids',
        key: 'manage-cannabinoids',
        icon: <TerpenesIcon />,
        route: '/manage-cannabinoids',
        component: <ManageCannabinoids />,
        roles: ['Super Admin', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
        screen: 'products',
      },
      {
        // type: 'collapse',
        name: 'Manage Main Strains',
        key: 'hyperwolf/manage_main_strain',
        icon: <ReorderIcon />,
        route: '/hyperwolf/manage_main_strain',
        component: <HyperwolfMainStrains />,
        roles: ['Super Admin', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
        screen: 'products',
      },
      {
        // type: 'collapse',
        name: 'Main Banners',
        key: 'hyperwolf/main_banners',
        icon: <ViewCarouselIcon />,
        route: '/hyperwolf/main_banners',
        component: <HyperwolfMainBanners />,
        roles: ['Super Admin', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
        screen: 'products',
      },
      {
        type: 'collapse',
        name: 'Manage Categories',
        key: 'hyperwolf/manage-categories',
        icon: <DashboardIcon />,
        route: '/hyperwolf/manage-categories',
        component: <ManageHyperwolfCategories />,
        roles: ['Super Admin', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
      },
    ]
  },
  {
    // type: 'collapse',
    name: 'Canabanoids',
    key: 'cannabinoids',
    icon: <TerpenesIcon />,
    route: '/cannabinoids/add',
    component: <AddCannabinoid />,
    roles: ['Super Admin', 'Shop Admin', 'Developer', 'Marketing Manager'],
    // screen: 'products'
  },
  {
    name: 'Canabanoids',
    key: 'cannabinoids',
    icon: <TerpenesIcon />,
    route: '/cannabinoids/:cannabinoidId',
    component: <AddCannabinoid />,
    roles: ['Super Admin', 'Shop Admin', 'Developer', 'Marketing Manager']
  },
  {
    name: 'Main Cannabinoids',
    key: 'manage-cannabinoids/main-cannabinoid',
    icon: <TerpenesIcon />,
    route: '/manage-cannabinoids/main-cannabinoid',
    component: <AddMainCannabinoid />,
    roles: ['Super Admin', 'Shop Admin', 'Developer', 'Marketing Manager']
  },
  {
    name: 'Main Cannabinoids',
    key: 'manage-cannabinoids/add-main-cannabinoid-banner',
    icon: <TerpenesIcon />,
    route: '/manage-cannabinoids/add-main-cannabinoid-banner',
    component: <AddCannabinoidBanner />,
    roles: ['Super Admin', 'Shop Admin', 'Developer', 'Marketing Manager']
  },
  {
    name: 'Main Cannabinoids',
    key: 'manage-cannabinoids/add-main-cannabinoid-banner',
    icon: <TerpenesIcon />,
    route: '/manage-cannabinoids/add-main-cannabinoid-banner/:bannerId',
    component: <AddCannabinoidBanner />,
    roles: ['Super Admin', 'Shop Admin', 'Developer', 'Marketing Manager']
  },
  {
    name: 'Main Cannabinoids',
    key: 'manage-cannabinoids/main-cannabinoid/main-page',
    icon: <TerpenesIcon />,
    route: '/manage-cannabinoids/main-cannabinoid/main-page',
    component: <MainPageCannbinoid />,
    roles: ['Super Admin', 'Shop Admin', 'Developer', 'Marketing Manager']
  },
  // {
  //   type: 'collapse',
  //   name: 'Products',
  //   key: 'hyperwolf_products',
  //   icon: <ViewCarouselIcon />,
  //   // route: '/hyperwolf/products',
  //   // component: <HyperwolfProducts />,
  //   roles: ['Super Admin', 'Developer'],
  //   collapse: [
  //     {
  //       type: 'collapse',
  //       name: 'Products',
  //       key: 'hyperwolf_products',
  //       icon: <ViewCarouselIcon />,
  //       route: '/hyperwolf/products',
  //       component: <HyperwolfProducts />,
  //       roles: ['Super Admin', 'Developer'],
  //       screen: 'products',
  //     },
  //     {
  //       type: 'collapse',
  //       name: 'Disclaimer',
  //       key: 'disclaimer',
  //       icon: <SettingsIcon />,
  //       route: '/disclaimer',
  //       component: <ManageDisclaimers />,
  //       roles: ['Super Admin', 'Developer'],
  //       screen: 'products',
  //     },
  //   ]
  // },
  {
    type: 'collapse',
    name: 'Products',
    icon: <ViewCarouselIcon />,
    roles: ['Super Admin', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
    screen: 'products',
    collapse: [
      {
        type: 'collapse',
        name: 'Products',
        key: 'hyperwolf/products',
        icon: <ViewCarouselIcon />,
        route: '/hyperwolf/products',
        component: <HyperwolfProducts />,
        roles: ['Super Admin', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
        screen: 'products',
      },
      {
        type: 'collapse',
        name: 'Disclaimer',
        key: 'disclaimer',
        icon: <SettingsIcon />,
        route: '/disclaimer',
        component: <ManageDisclaimers />,
        roles: ['Super Admin', 'Shop Admin', 'Shop Manager', 'Inventory Manager', 'Marketing Manager', 'Developer'],
        screen: 'products',
      },
    ]
  },
  {
    name: 'Manage Categories',
    key: 'hyperwolf/manage-categories/add',
    icon: <DashboardIcon />,
    route: '/hyperwolf/manage-categories/add',
    component: <AddHyperwolfCategory />,
    roles: ['Super Admin', 'Shop Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    type: 'collapse',
    name: 'Employees',
    key: 'hyperwolf/manage-employess',
    icon: <MembersIcon />,
    route: '/hyperwolf/manage-employess',
    component: <ManageEmployees />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Manage Employess',
    key: 'hyperwolf/manage-employess/add',
    icon: <MembersIcon />,
    route: '/hyperwolf/manage-employess/add',
    component: <AddEmployee />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Manage Sub Strains',
    key: 'hyperwolf/manage_strains/add',
    icon: <ReorderIcon />,
    route: '/hyperwolf/manage_strains/add',
    component: <AddHyperwolfStrain />,
    roles: ['Super Admin', 'Developer', 'Shop Admin', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Main Banners',
    key: 'hyperwolf/main_banners/add',
    icon: <ViewCarouselIcon />,
    route: '/hyperwolf/main_banners/add',
    component: <AddHyperwolfBanner />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Shop Admin', 'Sales Associate', 'Customer Support']
  },
  {
    type: 'collapse',
    name: 'Members',
    key: 'hyperwolf_members',
    icon: <MembersIcon />,
    route: 'hyperwolf/members',
    component: <HyperwolfMembers />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Members Details',
    key: 'hyperwolfMembers/details',
    icon: <MembersIcon />,
    route: 'hyperwolf/members/detail/:memberEmail',
    component: <HyperwolfMemberDetails />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']

  },
  {
    type: 'collapse',
    name: 'Orders',
    key: 'hyperwolf_orders',
    icon: <OrdersIcon />,
    route: '/hyperwolf/orders',
    component: <HyperwolfOrders />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Orders',
    key: 'orders',
    icon: <OrdersIcon />,
    route: '/orders/member/:memberId',
    component: <MemberOrders />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Orders',
    key: 'orders/add',
    icon: <OrdersIcon />,
    route: '/orders/add',
    component: <AddOrder />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Orders',
    key: 'orders/update',
    icon: <OrdersIcon />,
    route: '/orders/:orderId',
    component: <AddOrder />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Orders',
    key: 'orders/update',
    icon: <OrdersIcon />,
    route: 'hyperwolf/orders/details/:orderId',
    component: <HyperwolfOrderDetailsPage />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Hyperdrive',
    key: 'hyperdrive',
    // route: '/hyperdrive',
    route: `/${process.env.REACT_APP_MODE}/hyperdrive`,
    component: <HyperDrive />,
    roles: ['Super Admin', 'Developer']
  },
  {
    type: 'collapse',
    name: 'Driver/Breaks',
    key: 'hyperwolf/driver',
    icon: <DriverIcon />,
    route: '/hyperwolf/driver',
    component: <HyperwolfAdmin />,
    roles: ['Super Admin', 'Developer']
  },
  {
    name: 'Driver',
    key: 'hyperwolf/driver',
    icon: <DriverIcon />,
    route: '/hyperwolf/driver/create',
    component: <AddDriver />,
    roles: ['Super Admin', 'Developer']
  },
  {
    name: 'Retailer Details',
    key: 'retailers/update',
    icon: <MembersIcon />,
    route: '/hyperwolf/driver/:driverId',
    component: <AddDriver />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Driver Details',
    key: 'hyperwolf/driver/details',
    icon: <MembersIcon />,
    route: '/hyperwolf/driver/details/:driverId',
    component: <DriverDetails />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']

  },
  {
    name: 'Break',
    key: 'hyperwolf/driver/break',
    icon: <DriverIcon />,
    route: '/hyperwolf/break/create',
    component: <CreateBreak />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Approval',
    key: 'hyperwolf/driver/approval',
    icon: <DriverIcon />,
    route: '',
    component: <Approval />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  {
    name: 'Retailer Details',
    key: 'hyperwolf/driver/update',
    icon: <MembersIcon />,
    route: '/hyperwolf/break/:breakId',
    component: <AddBreak />,
    roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  },
  // {
  //   type: 'collapse',
  //   name: 'Settings',
  //   key: 'hyperwolf/shop_timing',
  //   icon: <SettingsIcon />,
  //   route: '/hyperwolf/shop_timing',
  //   component: <HyperwolfShopTiming />,
  //   roles: ['Super Admin', 'Developer', 'Shop Manager', 'Sales Associate', 'Customer Support']
  // },
];

export const hyperfDriveAdminRoutes = [
  {
    name: 'Home',
    key: 'hyperdrive_home',
    icon: <ViewCarouselIcon />,
    route: `/${process.env.REACT_APP_MODE}/hyperdrive/home`,
    component: (
      <Suspense fallback={<div><HyperdriveLoading /></div>}>
        <HyperDriveHomePage />
      </Suspense>
    ),
    roles: ['Super Admin', 'Developer']
  },
  {
    name: 'Site Setting',
    key: 'site-setting',
    icon: <ViewCarouselIcon />,
    route: `/${process.env.REACT_APP_MODE}/hyperdrive/site-setting`,
    component: (
      <Suspense fallback={<div><HyperdriveLoading /></div>}>
        <SiteSetting />
      </Suspense>
    ),
    roles: ['Super Admin', 'Developer']
  },
  {
    name: 'Driver Analytics',
    key: 'driver_analytics',
    icon: <ViewCarouselIcon />,
    route: `/${process.env.REACT_APP_MODE}/hyperdrive/driver-analytics/detail/:driverId`,
    component: (
      <Suspense fallback={<div><HyperdriveLoading /></div>}>
        <DriverAnalyticsDetails />
      </Suspense>
    ),
    roles: ['Super Admin', 'Developer']
  },
];

export default routes;