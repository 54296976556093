import { CloseRounded } from '@mui/icons-material';
import { Avatar, Box, Checkbox,  Grid, IconButton, InputAdornment,  Menu, MenuItem, Paper,  Stack, TextField, Tooltip, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import { AgGridTable } from 'components/tables/AgGridTable';
import CommonLayout from 'layouts/DashboardLayout';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { deleteProduct, fetchProducts } from '../../redux/slices/products';
import { formatTimestampToDate } from 'utilities/common';
import { closeCustomModalDialog, openCustomModalDialog, setModalLoader } from '../../redux/slices/modalSlice';
import MDBox from 'components/MDBox';
import StaticsOverview from 'common/StaticsOverview';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RibbonBadgeIcon from 'common/customIcons/ribbon_badge';
import RevenueIcon from 'common/customIcons/revenue';
import LeastDownIcon from 'common/customIcons/least_down';
import ActiveIcon from 'common/customIcons/active';
import ProductInactiveIcon from 'common/customIcons/product_inactive';
import EditIcon from 'common/customIcons/edit';
import DeleteIcon from 'common/customIcons/delete';
import { useDebounce } from 'hooks/useDebounce';
import StatusText from 'common/component/StatusText';
import { openSnackbar } from '../../redux/slices/common';
import { addCommas } from 'utilities/common';
import TrendChart from 'common/charts/TrendChart';
import defaultProductImage from '../../assets/images/placeholderproduct.svg';
import FilterProducts from 'common/CommonDrawer/products/FilterProducts';
import { capitalizeText } from 'utilities/common';
import SearchIcon from 'common/customIcons/search';

export default function Products() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading = false, products = {} } = useSelector(state => state.products);
  const { activeLength = 0, highestRevenueProductName = '', inactiveLength = 0, leastSellingProductName = '', topSellingProductName = '' } = products || {};
  const pageRef = useRef(0);
  const searchTermRef = useRef('');
  const mainGridApiRef = useRef('');
  const gridRefApi = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showBulkUpdate, setShowBulkUpdate] = useState(false);
  const productFiltersPayload = useRef({});
  const [isLoading, setIsLoading] = useState(true);

  const handleSearch = useDebounce(() => {
    // Perform search operation with the debounced term
    pageRef.current = 0;
    searchFn();
  }, 500);

  const selectAllHandler = (e) => {
    if (e.target.checked) {
      mainGridApiRef.current.forEachNode((node) => {
        node.setSelected(true);
      });
    } else {
      mainGridApiRef.current.forEachNode((node) => {
        node.setSelected(false);
      });
    }
  };

  const myComponent = (props) => {
    return <>
      <Stack direction='row' gap={2}>
        <Checkbox size='medium' sx={{ margin: '0px -6px' }} onChange={selectAllHandler} />
        <Typography variant='subtitle1' sx={{ fontSize: '12px', color: '#656575', lineHeight: 16, fontWeight: 400 }}>{props?.displayName}</Typography>
      </Stack>
    </>;
  };

  const columnDefs= [
    {
      headerName: 'Product Name/SKU',
      field: 'Product Name/SKU',
      checkboxSelection: true,
      suppressMenu: true,
      wrapText: true,
      autoHeight: true,
      headerComponent: myComponent,
      cellRenderer: (props) => {
        return <Tooltip title={capitalizeText(props?.data?.productName)} arrow placement='top'>
          <Link to={`/products/details/${props?.data?.productId}?filter=products`}>
            <Box display='flex' alignItems='center' sx={{ cursor: 'pointer' }}>
              <Avatar
                alt='Admin'
                src={props?.data?.productImages?.[0] || defaultProductImage}
                sx={{ width: 32, height: 32 }}
              />
              <MDBox textAlign='left' lineHeight={1.75} ml={1.25} mt={.125}>
                <Typography variant='h6' fontSize={14} color='dark' lineHeight={1.25} fontWeight='semibold' className='truncate line-1'>{capitalizeText(props?.data?.productName)}</Typography>
                <Typography variant='p' fontSize={12} color='secondary' lineHeight={1.25}>{props?.data?.sku}</Typography>
              </MDBox>
            </Box>
          </Link>
        </Tooltip>;
      },
      minWidth: 280
    },
    {
      headerName: 'Brand',
      field: 'brandName',
      suppressMenu: false,
      minWidth: 140
    },
    {
      headerName: 'Strain Type',
      field: 'strainType',
      suppressMenu: false,
      minWidth: 140
    },
    {
      headerName: 'Category',
      field: 'categoryName',
      key: 'fullName',
      suppressMenu: false,
      minWidth: 180,
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: 1.5
      }
    },
    {
      headerName: 'Inventory Trend',
      field: 'inventoryTrend',
      key: 'fullName',
      suppressMenu: false,
      cellRenderer: (props) => {
        return <div style={{ height: '40px', width: '100%', display: 'block' }}>
          {props?.value?.length > 0 ? <TrendChart inventoryTrend={props?.value} /> : '-'}
        </div>;
      },
    },
    {
      headerName: 'Available Quantity',
      field: 'totalQuantity',
      key: 'fullName',
      suppressMenu: false,
      minWidth: 220,

    },
    {
      headerName: 'Status',
      field: 'status',
      suppressMenu: false,
      cellRenderer: (props) => {
        return <StatusText status={props?.data?.status} />;
      },
      minWidth: 100
    },
    {
      headerName: 'Unit Price',
      field: 'unitPrice',
      suppressMenu: true,
      cellRenderer: (props) => {
        if (!props?.value?.toString()) return null;
        if (props?.value?.toString()) {
          return <Typography variant='body2' fontWeight='medium' lineHeight={1}>${props?.value || '0'}</Typography>;
        }
      },
      minWidth: 120
    },
    {
      headerName: 'Sale Price',
      field: 'salePrice',
      suppressMenu: true,
      cellRenderer: (props) => {
        if (!props?.value?.toString()) return;
        if (!props?.value) return '-';
        return < Typography variant='body2' fontWeight='medium' lineHeight={1} > ${props?.value || '0'}</Typography >;
      },
      minWidth: 120
    },
    {
      headerName: 'Action',
      suppressMenu: true,
      cellRenderer: (props) => {
        return <Box display='inline-flex' alignItems='center'>
          <IconButton onClick={(e) => {
            e.stopPropagation();
            onClickEdit(props);
          }} color='primary' size='small' variant='primary'>
            <EditIcon width='16px' height='16px' />
          </IconButton>
          <IconButton onClick={(e) => {
            e.stopPropagation();
            dispatch(openCustomModalDialog({ modalData: { deleteHandler, deleteId: props.data?.productId }, modal: 'DELETE_MODAL' }));
          }} color='error' size='small' variant='primary' sx={{ marginLeft: 1 }}>
            <DeleteIcon width='16px' height='16px' />
          </IconButton>
        </Box>;
      },
      cellStyle: { textAlign: 'right', justifyContent: 'end' },
      headerClass: 'right-aligned-cell-header',
      minWidth: 120,
      maxWidth: 100,
      pinned: 'right'
    },
  ];

  const getRows = useCallback(async (params) => {
    gridRefApi.current = params;
    const sortModel = params?.sortModel;
    let sortedColumnName = '';
    let sortDirection = '';
    if (sortModel.length > 0) {
      const sortedColumn = sortModel[0];
      sortedColumnName = sortedColumn.colId;
      sortDirection = sortedColumn.sort === 'asc' ? 'low' : 'high';
    }
    dispatch(fetchProducts({ filters: { skip: pageRef.current, limit: 1000, userType: 'admin', searchTerm: searchTermRef.current, [sortedColumnName]: sortDirection, status: '', ...productFiltersPayload?.current?.queryPayload }, payload: productFiltersPayload.current?.payload })).unwrap().then(res => {
      const { filteredProducts, total } = res;
      const productsArray = filteredProducts?.map((item => {
        return { ...item, id: item._id, totalQuantity: addCommas(item?.totalQuantity), createdDate: formatTimestampToDate(item?.createdDate) };
      }));
      params.successCallback(productsArray, total);
    });
  }, [productFiltersPayload.current]);

  const searchFn = () => {
    const dataSource = {
      getRows
    };
    if (mainGridApiRef.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  };


  const searchHandler = (searchValue) => {
    setSearchTerm(searchValue);
    searchTermRef.current = searchValue;
    handleSearch(searchValue);

    // Debounce the search callback
  };


  const onRowClicked = (row) => {
    if (row?.colDef?.headerName !== 'Action' && row?.colDef?.headerName !== 'Product Name/SKU') {
      navigate(`/products/details/${row?.data.productId}?filter=products`);
    }
  };

  const onClickEdit = (row) => {
    navigate(`/products/${row?.data.productId}?filter=products`);

  };

  const deleteHandler = (productId) => {
    dispatch(setModalLoader(true));
    dispatch(deleteProduct(productId)).unwrap().then((res) => {
      showError(res?.message, 'success');
      dispatch(closeCustomModalDialog());
      pageRef.current = 0;
      resetData();
    }).catch((err) => {
      dispatch(setModalLoader(false));
      showError(err?.message, 'error');
    });
  };

  const showError = (message, errortype) => {
    if (errortype === 'success') {
      dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }));
    }
    if (errortype === 'error') {
      dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }));
    }
  };

  const resetData = () => {
    pageRef.current = 0;
    if (mainGridApiRef.current) {
      mainGridApiRef.current.forEachNode((node) => {
        node.setSelected(false);
      });
    }

    searchFn();
    setAnchorEl(null);
  };

  const bulkUpdate = (type = '') => {

    // Assuming you have a reference to your Ag-Grid instance, e.g., agGridInstance
    const selectedRows = mainGridApiRef.current.getSelectedRows();
    // Extracting IDs from selected rows
    const selectedIds = selectedRows.map(row => row.productId);
    if (selectedIds?.length > 0) {
      setAnchorEl(null);
      dispatch(openCustomModalDialog({
        modalData: {
          bulkProductIds: selectedIds, resetData
        }, modal: type || 'BULK_DELETE_PRODUCT'
      }));
    }
  };

  const onSelectionChanged = () => {
    const selectedRows = mainGridApiRef.current.getSelectedRows();
    if (selectedRows?.length > 0) {
      setShowBulkUpdate(true);
    } else {
      setShowBulkUpdate(false);
    }
  };
  const applyProductFilter = (filterPayload) => {
    pageRef.current = 0;
    productFiltersPayload.current = filterPayload;
    const dataSource = {
      getRows: (params) => {
        dispatch(fetchProducts({ filters: { skip: pageRef.current, limit: 1000, userType: 'admin', searchTerm: searchTermRef.current, ...filterPayload?.queryPayload }, payload: filterPayload?.payload })).unwrap()
          .then(res => {
            const { filteredProducts, total } = res;
            const productsArray = filteredProducts?.map((item => {
              return { ...item, id: item._id, totalQuantity: addCommas(item?.totalQuantity), createdDate: formatTimestampToDate(item?.createdDate) };
            }));
            params.successCallback(productsArray, total);
          }).catch(() => {
            params.successCallback([], 0);
          });
      }
    };
    if (mainGridApiRef?.current) {
      mainGridApiRef.current.ensureIndexVisible(0, null);
      mainGridApiRef.current.setGridOption('datasource', dataSource);
    }
  };

  const getQuryValues = (data) => {
    productFiltersPayload.current = data;
    setIsLoading(false);
  };

  useEffect(() => {
    if (mainGridApiRef?.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (products?.total === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      }
      else {
        mainGridApiRef.current.hideOverlay();
      }
    }

  }, [products, loading]);



  return (
    <CommonLayout>
      <Box p={{ xs: 2, sm: 3, lg: 4 }} pb={{ lg: 2 }}>
        <Paper elevation={0} style={{ borderRadius: '12px' }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems='start' justifyContent='space-between' bgcolor='white' p={{ xs: 1, md: 2 }}>
            <StaticsOverview
              color='success'
              iconShadow='iconShadowSuccess'
              icon={<RibbonBadgeIcon />}
              title='Most Sold'
              count={topSellingProductName}
            />
            <StaticsOverview
              color='info'
              iconShadow='iconShadowInfo'
              icon={<RevenueIcon />}
              title='Highest Revenue'
              // count={highestRevenueProductName}
              count={`${highestRevenueProductName}`}
            />
            <StaticsOverview
              color='error'
              iconShadow='iconShadowDanger'
              icon={<LeastDownIcon />}
              title='Least Sold'
              count={leastSellingProductName}
            />
            <StaticsOverview
              color='success'
              iconShadow='iconShadowSuccess'
              icon={<ActiveIcon />}
              title='Active'
              count={activeLength}
            />
            <StaticsOverview
              color='warning'
              iconShadow='iconShadowWarning'
              icon={<ProductInactiveIcon />}
              title='Inactive'
              count={inactiveLength}
            />
          </Stack>
        </Paper>

        <Grid container alignItems='center' rowSpacing={2} columnSpacing={2} mt={0} mb={1}>
          <Grid item xs={12} sm={2}>
            <Typography variant='h4'>Products</Typography>
          </Grid>

          <Grid item xs={12} sm={10}>
            <Stack direction='row' justifyContent={{ xs: 'start', sm: 'end' }} flexWrap='wrap' alignItems='stretch' gap={2}>
              {/* <Search  placeholder='Search' label='' value={searchTerm} onChange={searchHandler} /> */}

              <TextField className='custom-search-field' placeholder='Search' label='' value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>
                    <IconButton size='small'>
                      <SearchIcon width='18px' height='18px' />
                    </IconButton>
                  </InputAdornment>,
                  endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                    <IconButton onClick={() => searchHandler('')} size='small'>
                      <CloseRounded />
                    </IconButton>
                  </InputAdornment> : null
                }}
              />

              <Link to='/products/add'>
                <MDButton style={{ height: '100%' }}
                  startIcon={<AddRoundedIcon fontSize='large' />}
                  variant='contained' color='primary'
                >
                  Add Product
                </MDButton>
              </Link>

              {
                <>
                  <MDButton disabled={!showBulkUpdate} variant='contained' color='warning' onClick={(e) => setAnchorEl(e.currentTarget)}>Bulk Update</MDButton>
                  <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    sx={{ mt: 0.5 }}
                  >
                    <MenuItem onClick={() => bulkUpdate('BULK_UPDATE_STATUS')}>Change Status</MenuItem>
                    <MenuItem onClick={() => bulkUpdate('BULK_DELETE_PRODUCT')}>Delete Product</MenuItem>
                    <MenuItem onClick={() => bulkUpdate('BULK_UPDATE_BRAND')}>Change Brand</MenuItem>
                    <MenuItem onClick={() => bulkUpdate('BULK_UPDATE_PRICE')}>Edit Price</MenuItem>
                    <MenuItem onClick={() => bulkUpdate('BULK_UPDATE_SALE_PRICE')}>Edit Sale Price</MenuItem>
                    <MenuItem onClick={() => bulkUpdate('BULK_UPDATE_CATEGORY')}>Edit Category</MenuItem>
                    <MenuItem onClick={() => bulkUpdate('BULK_UPDATE_TAGS')}>Edit Tags</MenuItem>
                    {/* <MenuItem >Change Flower Type</MenuItem> */}
                  </Menu>
                </>
              }
            </Stack>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FilterProducts applyProductFilter={applyProductFilter} setIsLoading={setIsLoading} getQuryValues={getQuryValues} productFiltersPayload={productFiltersPayload?.current} />
          </Grid>
        </Grid>
        {
          !isLoading && <AgGridTable
            col={columnDefs}
            mainGridApiRef={mainGridApiRef}
            getRows={getRows}
            pageRef={pageRef}
            isLoading={loading}
            onRowClicked={onRowClicked}
            style={{ height: '56vh' }}
            gridOptions={{
              rowHeight: 64,
            }}
            maxLimit={1000}
            onSelectionChanged={onSelectionChanged}
          />
        }
      </Box>

    </CommonLayout>
  );

}